import React, { useMemo } from 'react'

import SettingsItem from '../../common/views/settingsItem/settingsItem'
import { Alert, Button, LoadingOverlay, PageHeader } from '../../common'
import { useSettings, useSettingsItems } from '../../../hooks'
import { isRequired } from '../../../common/utils/validators'
import { Severity } from '../../../types'

import { CustomFieldMapper, parseSettingsObject } from './helpers'
import { isEmptyObj } from '../../common/form/helper'

const ItemRecord = () => {
   const {
      error: settingsError,
      isLoading,
      isSuccess,
      isUpdating,
      settings,
      update: updateSettings,
   } = useSettings({ type: 'item-record' })
   const {
      changedData,
      errors: customFieldErrors,
      isSubmitted,
      onChangeCustomField,
      onChangeToggle,
      onValidate,
   } = useSettingsItems()
   const handleSave = () => {
      const isValid = onValidate(settings, customFields)

      if (isValid) {
         updateSettings({ ...settings, ...changedData })
      }
   }

   const allowedSettings = [
      'RequireTerms',
      'CustomText1Enabled',
      'CustomText2Enabled',
      'CustomDate1Enabled',
      'CustomDate2Enabled',
      'CustomURL1Enabled',
      'CustomURL2Enabled',
      'CustomRentalRate1Enabled',
      'CustomRentalRate2Enabled',
      'CustomRentalRate3Enabled',
      'CustomRentalRate4Enabled',
   ]

   const customFields: CustomFieldMapper = {
      RequireTerms: {
         key: 'Terms',
         label: 'Terms',
         position: 'before',
         type: 'textarea',
         validate: isRequired,
      },
      CustomText1Enabled: {
         key: 'CustomText1Label',
         label: 'Custom Text 1 Label',
         position: 'before',
         type: 'text',
         validate: isRequired,
      },
      CustomText2Enabled: {
         key: 'CustomText2Label',
         label: 'Custom Text 2 Label',
         type: 'text',
         validate: isRequired,
      },
      CustomDate1Enabled: {
         key: 'CustomDate1Label',
         label: 'Custom Date 1 Label',
         type: 'text',
         validate: isRequired,
      },
      CustomDate2Enabled: {
         key: 'CustomDate2Label',
         label: 'Custom Date 2 Label',
         type: 'text',
         validate: isRequired,
      },
      CustomURL1Enabled: {
         key: 'CustomURL1Label',
         label: 'Custom URL 1 Label',
         type: 'text',
         validate: isRequired,
      },
      CustomURL2Enabled: {
         key: 'CustomURL2Label',
         label: 'Custom URL 2 Label',
         type: 'text',
         validate: isRequired,
      },
      CustomRentalRate1Enabled: {
         key: 'CustomRentalRate1Label',
         label: 'Custom Rental Rate 1 Label',
         position: 'before',
         type: 'text',
         validate: isRequired,
      },
      CustomRentalRate2Enabled: {
         key: 'CustomRentalRate2Label',
         label: 'Custom Rental Rate 2 Label',
         type: 'text',
         validate: isRequired,
      },
      CustomRentalRate3Enabled: {
         key: 'CustomRentalRate3Label',
         label: 'Custom Rental Rate 3 Label',
         type: 'text',
         validate: isRequired,
      },
      CustomRentalRate4Enabled: {
         key: 'CustomRentalRate4Label',
         label: 'Custom Rental Rate 4 Label',
         type: 'text',
         validate: isRequired,
      },
   }

   const parsedSettings = useMemo(
      () => parseSettingsObject(settings, allowedSettings, customFields),
      [settings]
   )

   const renderErrorMessage = useMemo(() => {
      if (!isSubmitted) return null

      if (!isEmptyObj(customFieldErrors)) {
         return 'There are errors with your custom fields, see above'
      }

      return settingsError
   }, [isSubmitted, customFieldErrors, settingsError])

   return (
      <>
         <PageHeader title="Item Record" extraMargin />

         {isLoading ? (
            <LoadingOverlay />
         ) : (
            <>
               {parsedSettings &&
                  parsedSettings.map((item) => {
                     return (
                        <SettingsItem
                           key={item.id}
                           customField={item?.customField}
                           description={item.description}
                           descriptionLocation={item?.customField?.position}
                           error={customFieldErrors[item?.customField?.key]}
                           id={item.id}
                           isEnabled={
                              changedData.hasOwnProperty(item.id)
                                 ? changedData[item.id]
                                 : item.value
                           }
                           label={item.name}
                           onChangeCustomField={onChangeCustomField}
                           onToggle={onChangeToggle}
                        />
                     )
                  })}

               {renderErrorMessage && <Alert message={renderErrorMessage} />}

               {isSuccess && (
                  <Alert
                     message="Item record settings updated successfully"
                     severity={Severity.SUCCESS}
                  />
               )}

               <Button loading={isUpdating} onClick={handleSave}>
                  Save
               </Button>
            </>
         )}
      </>
   )
}

export default ItemRecord
